import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import {
    AppBase,
    ScrollToTop,
    sendEventAndRoute,
    TranslatePage,
    useEvents,
    UserNavigationEvent,
} from "@ingka-livlig/frontend-lib";
import { QueryParamProvider, StringParam, useQueryParam } from "use-query-params";
import { MapView } from "./MapView";
import { Map3DView } from "./Map3DView";
import { localeActivate } from "./i18n";
import { withStore } from "react-context-hook";
import NotFound from "./NotFound";
import { useUserAPI } from "./userAPI";
import { ChooseLocation, InitialRedirect } from "./ChooseLocation";
import { Training } from "./Training";
import Features from "./Features";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";
import { useLingui } from "@lingui/react";
import * as englishMessages from "./locales/en/messages.mjs";
import { RemoteLogin, RemoteLoginValidate } from "./RemoteLogin";

/**
 * Just redirect the browser based on what's in the "goto" query param.
 * @constructor
 */
function GotoPage() {
    const [goto] = useQueryParam("goto", StringParam);
    if (goto) {
        window.location.replace(goto);
        return null;
    } else {
        return <Redirect to={"/"} />;
    }
}

const Translate = () => {
    const api = useUserAPI();
    return (
        <TranslatePage
            recordTranslation={api.recordTranslation}
            fetchEnglishTransations={() => englishMessages.messages}
        />
    );
};

function Routes() {
    const events = useEvents<UserNavigationEvent>(useUserAPI());
    /**
     * This is put here in order to force Lingui to remount when language changes.
     * With Lingui 4 the default is to only rerender when language us changed. This works nicely when <Trans> elements
     * are used, but doesn't work when the t`...` templated string is used. The result is a lot of untranslated strings.
     * We handle this by remounting everything, which shouldn't be an issue as it's expected that users seldomly
     * change languages.
     *
     * In addition, we rely on the selected locale to fetch proper names of HFBs and PAs from the server, so we need to
     * trigger a refetch anyway.
     */
    useLingui();

    return (
        <Switch>
            <Route exact path="/" render={sendEventAndRoute("Root", InitialRedirect, events)} />
            <Route exact path="/choose-location" render={sendEventAndRoute("ChooseLocation", ChooseLocation, events)} />
            <Route exact path="/translate" render={sendEventAndRoute("Translate", Translate, events)} />
            <Route exact path="/map" render={sendEventAndRoute("Map", MapView, events)} />
            <Route exact path="/map3d" render={sendEventAndRoute("Map3D", Map3DView, events)} />
            <Route exact path="/goto-page" component={() => <GotoPage />} />
            <Route exact path="/training" render={sendEventAndRoute("Training", Training, events)} />
            <Route exact path="/training/lesson/:lessonId" render={sendEventAndRoute("Lesson", Training, events)} />
            <Route exact path="/features" render={sendEventAndRoute("Features", Features, events)} />
            <Route exact path="/qr/:sessionId" render={sendEventAndRoute("RemoteLogin", RemoteLoginValidate, events)} />
            <Route path="/" render={sendEventAndRoute("NotFound", NotFound, events)} />
        </Switch>
    );
}

function MainRouter() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Switch>
                <Route exact path="/qr">
                    <RemoteLogin />
                </Route>
                <Route>
                    <AppBase localeActivate={localeActivate}>
                        <QueryParamProvider adapter={ReactRouter5Adapter}>
                            <Routes />
                        </QueryParamProvider>
                    </AppBase>
                </Route>
            </Switch>
        </BrowserRouter>
    );
}

export default withStore(MainRouter, {});
